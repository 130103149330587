<template>
  <div class="grey darken-4">
    <BannerHeader
      title="Civil Division"
      uuid="ee142958-7476-43ce-a21b-e9635fc70eda"

    />
    <ServicesContent
      :bullets="bullets"
      class="mt-12"
      :images="images"
    >
      <div class="grey--text text--lighten-2">
        <p>Here at C&C Industrial, Inc we pride ourselves in being a turn key construction company when it comes to site preparation, rough grading, and finished grading.
        </p>
        <p>
        We can perform full site preparation and grading services including clearing, storm drain, utilities, stone placement, site concrete, and asphalt. The final step in the grading process would be the finish grading,  the ground is leveled and smoothed, drainage is perfected and programmed to flow away from buildings and/or sites.
        </p>
      </div>
    </ServicesContent>
    <v-divider />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '../components/ServicesContent'
export default {
  metaInfo: {
    title: 'Civil Division',
    description: 'Here at C&C Industrial, Inc we pride ourselves in being a turn key construction company when it comes to site preparation, rough grading, and finished grading.'
  },
  components: {
    BannerHeader,
    ServicesContent

  },
  data () {
    return {
      bullets: [
        {
          title: 'Excavating'
        },
        {
          title: 'Commercial'
        },
        {
          title: 'Rough Grading'
        },
        {
          title: 'Fine Grading'
        }
      ],
      images: [
        {
          uuid: '6392b153-b067-415e-9e9c-df0e5ed52f88',
          alt: 'Site Clearing - Lillington, NC'
        },
        {
          uuid: '3137af48-714b-400b-9d82-0af67109df5b',
          alt: 'Site Clearing'
        },
        {
          uuid: 'a72b3141-aa6a-492a-9409-5eeaacc53c4c',
          alt: 'Site Clearing'
        },
        {
          uuid: '0ec1affa-cd23-4a42-a52a-e419db63c5e5',
          alt: 'Rough Grading'
        },
        {
          uuid: '5c33fe49-8bd0-4ac3-9a01-2b8cb9dfe9ef',
          alt: 'Rocky Mount Eye before construction'
        },
        {
          uuid: '5524775c-4845-4209-b0b4-ee5cc5c7059d',
          alt: 'Rocky Mount Eye completed site'
        },
        {
          uuid: 'a8ee0328-1efe-4ba6-97a2-14f80dd436fc',
          alt: 'Macon Ridge Site Prep - Nashville, NC'
        },
        {
          uuid: '852532b6-7b17-4e18-b550-ecb94c5146da',
          alt: 'Macon Ridge Retention Basin - Nashville, NC'
        },
        {
          uuid: '0bde6ed4-632c-4534-b4cf-176a44c4d81e',
          alt: 'Macon Ridge - Nashville, NC'
        },
        {
          uuid: '470ea9ff-9a38-4806-9dc5-8914415d97a1',
          alt: 'Storm Drain Installation'
        },
        {
          uuid: '41f155f3-14cc-4edd-80ff-309363bd1deb',
          alt: 'Curb & Sidewalk'
        },
        {
          uuid: '490dd0f6-c450-43ee-bf36-510f4d5a857f',
          alt: 'Curb & Sidewalk'
        },
        {
          uuid: 'b1ee8256-7da3-4484-97c8-aef56c796c42',
          alt: 'Asphalt Paving'
        },
        {
          uuid: '6e9cbbc2-d782-4f88-a838-4ec25b7132b4',
          alt: 'Asphalt Paving'
        },
        {
          uuid: '192e1fca-b7c7-4fbd-b1c7-39d2fd8ec6a1',
          alt: 'Asphalt Paving'
        },
        {
          uuid: 'e8103a7d-45ba-49ec-be71-972f884315d6',
          alt: 'Loading Bar Material at Quarry'
        }
      ]
    }
  }
}
</script>
